import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext, Box } from 'grommet';
import { storyblokEditable } from 'gatsby-source-storyblok';
import { replace } from '../../lib/nodash';

import ContentSlider from '../ContentSlider';
import { StoryblokComponent } from 'gatsby-source-storyblok';

const DynamicContentSlider = ({ blok }) => {
  const {
    items,
    spaceBetween,
    slidesPerView,
    mobileSlidesPerView,
    navigation,
    navColor,
    loop,
  } = blok;
  const theme = useContext(ThemeContext);
  const pixelsBetween = spaceBetween
    ? parseInt(theme.global.edgeSize[spaceBetween])
    : 0;

  const color = navColor
    ? replace('#', '', theme.global.colors[navColor])
    : undefined;

  const params = {
    slidesPerView: parseInt(mobileSlidesPerView),
    spaceBetween: pixelsBetween,
    centeredSlides: false,
    navigation,
    loop,
    breakpoints: {
      [theme.global.breakpoints.small.value]: {
        slidesPerView: parseInt(slidesPerView),
        spaceBetween: pixelsBetween,
        centeredSlides: false,
      },
    },
  };
  return (
    <Box {...storyblokEditable(blok)} fill>
      <ContentSlider
        params={params}
        navColor={color}
        swiperStyle={{ width: '100%' }}
      >
        {items.map((x, i) => (
          <Box flex={false} fill key={x.uuid || x._uid || i}>
            <StoryblokComponent blok={x} />
          </Box>
        ))}
      </ContentSlider>
    </Box>
  );
};

DynamicContentSlider.propTypes = {
  blok: PropTypes.shape({
    items: PropTypes.array,
    slidesPerView: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mobileSlidesPerView: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    spaceBetween: PropTypes.oneOf([
      '',
      'xsmall',
      'small',
      'medium',
      'large',
      'xlarge',
    ]),
    navigation: PropTypes.bool,
    navColor: PropTypes.object,
    loop: PropTypes.bool,
  }),
};

export default DynamicContentSlider;
